import React from 'react';
import PropTypes from 'prop-types';
import {
  TwitterShareButton,
  LinkedinShareButton,
  TwitterIcon,
  LinkedinIcon,
} from 'react-share';

const iconColor = '#b4b4b4';
const iconSize = 43;

const SocialShare = ({
  hashtags,
  description,
  title,
  url,
}) => (
  <div className="social-share">
    <div className="social-share--container">
      <div className="social-share--title">
        Share
      </div>

      <div className="social-share--links">
        <TwitterShareButton
          hashtags={hashtags}
          title={title}
          url={url}
        >
          <TwitterIcon
            iconBgStyle={{ fill: 'transparent' }}
            logoFillColor={iconColor}
            size={iconSize}
          />
        </TwitterShareButton>

        <LinkedinShareButton
          description={description}
          title={title}
          url={url}
        >
          <LinkedinIcon
            iconBgStyle={{ fill: 'transparent' }}
            logoFillColor={iconColor}
            size={iconSize}
          />
        </LinkedinShareButton>
      </div>
    </div>
  </div>
);

SocialShare.propTypes = {
  description: PropTypes.string,
  hashtags: PropTypes.arrayOf(
    PropTypes.string,
  ),
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

SocialShare.defaultProps = {
  description: '',
  hashtags: [],
};

export default SocialShare;
