import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
// import Link from 'components/Link';
import Card from 'components/Card';

const RecentPosts = ({ posts, title }) => (
  <div className="recent-posts mv5">
    <h1>{title}</h1>

    <div className="card--list">
      {posts
        .filter((post) => post.node.frontmatter.title.length > 0)
        .map(({ node: post }) => (
          <Card
            key={post.id}
            date={post.frontmatter.date}
            summary={post.excerpt}
            title={post.frontmatter.title}
            url={post.fields.postPath}
          />
        ))}
    </div>

    <div className="tc mt4">
      <Link to="/insights" className="btn btn--secondary btn-outline">
        Explore Insights
      </Link>
    </div>
  </div>
);

RecentPosts.propTypes = {
  title: PropTypes.string.isRequired,
  posts: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default RecentPosts;
