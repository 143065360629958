import React from 'react';
import PropTypes from 'prop-types';

const CircleArrow = ({ className, color, left }) => {
  if (left) {
    return (
      <svg
        className={className}
        width="60"
        height="60"
        viewBox="0 0 60 60"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          fill="none"
          fillRule="evenodd"
          transform="matrix(-1 0 0 1 59 1)"
        >
          <circle
            cx="29"
            cy="29"
            r="29"
            fill="#FFF"
            stroke={color}
          />
          <g stroke="#CFBDA1" strokeLinecap="round" transform="translate(18 23)">
            <path d="M10.2235991,16.5106075 L10.2235991,-3.51513871" transform="matrix(0 1 1 0 3.724 -3.724)" />
            <polyline strokeLinejoin="round" points="11.792 3.208 18.273 9.59 24.59 3.369" transform="matrix(0 1 1 0 11.792 -11.792)" />
          </g>
        </g>
      </svg>
    );
  }

  return (
    <svg
      className={className}
      width="60"
      height="60"
      viewBox="0 0 60 60"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd" transform="translate(1 1)">
        <circle cx="29" cy="29" r="29" fill="#FFF" stroke={color} />
        <g stroke={color} strokeLinecap="round" transform="translate(18 23)">
          <path d="M10.2235991,16.5106075 L10.2235991,-3.51513871" transform="matrix(0 1 1 0 3.724 -3.724)" />
          <polyline strokeLinejoin="round" points="11.792 3.208 18.273 9.59 24.59 3.369" transform="matrix(0 1 1 0 11.792 -11.792)" />
        </g>
      </g>
    </svg>
  );
};

CircleArrow.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  left: PropTypes.bool,
};

CircleArrow.defaultProps = {
  className: 'PropTypes.string',
  color: '#CFBDA1',
  left: false,
};

export default CircleArrow;
