import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { graphql, withPrefix } from 'gatsby';
import RehypeReact from 'rehype-react';
import Link from 'components/Link';
import MarkdownComponents from 'components/markdown-components';
import Arrow from 'components/Arrow';
import CircleArrow from 'components/CircleArrow';
import PageTemplate from 'templates/PageTemplate';
import SocialShare from 'components/SocialShare';
import RecentPosts from 'components/RecentPosts';
import Glass from 'images/glass-ceiling.jpg';
import Utils from '../utils';

const PostTemplate = ({ data, pathContext }) => {
  const { post, recent } = data;
  const { postPath } = post.fields;
  const {
    author,
    background,
    backgroundAlt,
    date,
    excerpt,
    title,
    summary,
  } = post.frontmatter;
  const { previous, next } = pathContext;
  const fullPath = `https://www.outvox.com${postPath}`;

  const renderHtml = () => {
    const renderAst = new RehypeReact({
      createElement: React.createElement,
      components: MarkdownComponents,
    }).Compiler;

    return renderAst(post.htmlAst);
  };

  const getAuthor = () => {
    if (author) {
      return (
        <p className="post--author">
          by {author}
        </p>
      );
    }

    return null;
  };

  const hasBackground = () => background && Utils.has(background, 'childImageSharp') && Utils.has(background.childImageSharp, 'resize') && Utils.has(background.childImageSharp.resize, 'src');

  const getBackground = () => {
    if (hasBackground()) {
      return (
        <img
          src={background.childImageSharp.resize.src}
          alt={backgroundAlt}
          width="700px"
          height="auto"
          className="shadow mt4 mt0-l"
        />
      );
    }

    return (
      <img
        src={Glass}
        alt="Architectural gold glass ceiling"
        width="700px"
        height="auto"
        className="shadow mt4 mt0-l"
      />
    );
  };

  const recentPosts = () => {
    if (Utils.has(recent, 'edges')) {
      const { edges } = recent;

      return (
        <aside className="container">
          <RecentPosts posts={edges} title="Recent Insights" />
        </aside>
      );
    }

    return null;
  };

  const openGraphTags = () => {
    const ogArray = [
      { property: 'og:title', content: title },
      { property: 'og:type', content: 'article' },
      { property: 'og:url', content: fullPath },
      { property: 'og:article:published_time', content: new Date(date).toISOString() },
    ];
    let backgroundImage = Glass;

    if (author) {
      ogArray.push({ property: 'og:author', content: author });
    }

    if (hasBackground()) {
      backgroundImage = background.childImageSharp.resize.src;
    }

    ogArray.push({ property: 'og:image', content: `https://www.outvox.com${backgroundImage}` });

    if (summary) {
      ogArray.push({ property: 'og:description', content: summary });
    }

    return ogArray;
  };

  return (
    <PageTemplate className="post container">
      <Helmet
        title={title}
        script={[{
          type: 'application/ld+json',
          innerHTML: `{
            "@context": "http://schema.org",
            "@type": "BlogPosting",
            "url": "${fullPath}",
            "wordCount": "${post.wordCount.words}",
            "dateCreated": "${new Date(date).toISOString()}",
            "datePublished": "${new Date(date).toISOString()}",
            "mainEntityOfPage": {
              "@type": "WebPage",
              "@id": "https://www.outvox.com/"
            },
            "author": {
              "@type": "Person",
              "name": "${author}"
            },
            "publisher": {
              "@type": "Organization",
              "name": "OutVox",
              "telephone": "202 379 3085",
              "logo": {
                "@type": "ImageObject",
                "url": "https://www.outvox.com${withPrefix('/assets/outvox-logo_gray.png')}"
              }
            },
            "headline": "${title}",
            "image": "https://www.outvox.com${Glass}"
          }`,
        }]}
        meta={openGraphTags()}
      />

      <header className="post--header">
        <div className="header__content">
          <Link to="/insights" className="post--back-link">
            <Arrow className="mr2" left />
            Back to Insights
          </Link>

          <h1 className="post--title">
            {title}
          </h1>

          <h2 className="post--summary">
            {excerpt}
          </h2>

          {getAuthor()}

          <SocialShare
            title={title}
            description={summary}
            url={`https://www.outvox.com${postPath}`}
          />
        </div>

        {getBackground()}
      </header>

      <section className="post--content container">
        <nav className="prev">
          <Link to={`/insights${previous.fields.slug}`}>
            <span>
              {previous.frontmatter.title}
            </span>

            <CircleArrow left />
          </Link>
        </nav>

        <nav className="next">
          <Link to={`/insights${next.fields.slug}`}>
            <span>
              {next.frontmatter.title}
            </span>

            <CircleArrow />
          </Link>
        </nav>

        <div className="post--body">
          <div className="content">
            {renderHtml()}
          </div>

          <div className="tc mt5">
            <SocialShare
              title={title}
              description={summary}
              url={postPath}
            />
          </div>
        </div>
      </section>

      <aside>
        {recentPosts()}
      </aside>
    </PageTemplate>
  );
};

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    post: markdownRemark(
      fields: { slug: { eq: $slug } }
      fileAbsolutePath: {regex: "/(../posts)/.*.md$/"}
      frontmatter: { example: { ne: true } }
    ){
      htmlAst
      wordCount {
        words
      }
      fields {
        postPath
        slug
      }
      frontmatter {
        author
        background {
          childImageSharp {
            resize(width: 700, quality: 90) {
              src
            }
          }
        }
        backgroundAlt
        date(formatString: "MMMM DD, YYYY")
        title
        summary
      }
    }

    recent: allMarkdownRemark(
      filter: {
        fields: {
          slug: { ne: $slug }
        }
        frontmatter: {
          example: { ne: true }
        }
        fileAbsolutePath: {regex: "/(..\/posts)/.*\\.md$/"}
      }
      sort: {
        order: DESC,
        fields: [frontmatter___date]
      }
      limit: 3
    ) {
      edges {
        node {
          excerpt(pruneLength: 200)
          id
          fields {
            postPath
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
          }
        }
      }
    }
  }
`;

PostTemplate.propTypes = {
  data: PropTypes.shape({
    post: PropTypes.object.isRequired,
    recent: PropTypes.object,
  }).isRequired,
  pathContext: PropTypes.shape({
    previous: PropTypes.object.isRequired,
    next: PropTypes.object.isRequired,
  }).isRequired,
};

export default PostTemplate;
